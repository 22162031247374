import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide} from 'vue';
const GoodsTypeCardHelper = defineComponent({
    name:'GoodsTypeCard',
    title:'货物类型',
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: false,
                modelPath: "/goodsType"
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
            }
        })
        onMounted(()=>{
        })
        //---------------------------computed---------------------------
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        return{
            ...toRefs(dataObj),beforeOpen,beforeSaveHandler
         
        }
    }
});
export default GoodsTypeCardHelper;